import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import PhaseForm, { FormValues, validate } from '@asaprint/asap/components/forms/PhaseForm.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { BUTTON_UPDATE } from '@asaprint/asap/locales/client.js';
import { ADMIN_PHASES_ROUTE, DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import { EditPhase_Load, EditPhase_Save } from '@asaprint/asap/routes/__authenticated/admin/phases/$id.edit.graphql';
import {
  EditPhase_LoadQuery,
  EditPhase_LoadQueryVariables,
  EditPhase_SaveMutation,
  EditPhase_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import { toOption as phaseGroupToOption } from '@asaprint/common/helpers/PhaseGroup.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Loading from '@engined/client/components/Loading.js';
import { ConnectedSubmitButtons } from '@engined/client/components/SubmitButtons.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

const logger = getLogger('@asap/routes/admin/phases/$id.edit');

interface OwnProps {}

type Props = OwnProps;

const IdEdit: React.FunctionComponent<Props> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, data, error } = useQuery<EditPhase_LoadQuery, EditPhase_LoadQueryVariables>(EditPhase_Load, {
    variables: { id },
  });

  const [saveExecute] = useMutation<EditPhase_SaveMutation, EditPhase_SaveMutationVariables>(EditPhase_Save);

  const onSubmit: OnSubmit<FormValues> = async ({ group, color, ...values }: FormValues) => {
    try {
      const response = await saveExecute({
        variables: {
          input: {
            ...values,
            color: color.slice(1), // keep only value without #
            group: group?.value ?? null,
          },
        },
      });
      enqueueSnackbar(
        <Box>
          Úspešne ste upravili fázu <strong>{response.data.phaseSave.name}</strong>.
        </Box>,
        {
          variant: 'success',
        },
      );
      navigate(ADMIN_PHASES_ROUTE, { state: { ignoreFormPrompt: true } });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const phase = data?.phase;
  const initialValues = useMemo(
    (): FormValues =>
      phase && {
        id: phase.id,
        updatedAt: phase.updatedAt,
        name: phase.name || '',
        position: phase.position,
        color: `#${phase.color}`,
        group: phase.group ? phaseGroupToOption(phase.group) : null,
        calculatorName: phase.calculatorName,
      },
    [phase],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faLayerGroup} /> Úprava fázy
          </>
        }
      />

      <PagePaper>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <Form defaultValues={initialValues} onSubmit={onSubmit} validate={validate}>
            <PhaseForm showPosition />
            <ConnectedSubmitButtons submitLabel={t(BUTTON_UPDATE)} backTo={ADMIN_PHASES_ROUTE} />
          </Form>
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.PhasesManage)(IdEdit);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  const result = await apollo.query<EditPhase_LoadQuery, EditPhase_LoadQueryVariables>({
    query: EditPhase_Load,
    variables: { id: params.id },
  });
  return result.data;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Fázy produktu',
      to: ADMIN_PHASES_ROUTE,
    },
    {
      text: 'Úprava fázy',
    },
  ],
  meta: ({ meta }: MetaFunctionArgs) => ({
    title: `Úprava fázy | Fázy produktu | Administrácia | ${meta.title}`,
  }),
};
